

















import { Component, Prop, Vue } from 'vue-property-decorator';
import { ConservatorshipDetails } from '@/modules/conservatorship/types';

const ContactPlugin = () => import('@/modules/conservatorshipToContact/components/ContactPlugin.vue');
const DepartmentPlugin = () => import('@/modules/conservatorshipToContact/components/DepartmentPlugin.vue');

@Component({
  components: { ContactPlugin, DepartmentPlugin }
})
export default class ContactTab extends Vue {
  @Prop({ type: Object }) conservatorship?: ConservatorshipDetails;
}
